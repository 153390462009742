import React from "react"
import { graphql } from "gatsby"
import Layout from "../layout/layout"
import { withTranslation } from "react-i18next"
import Seo from "../components/seo/seo"

const Articolo = ({ data }) => {
  
    return (
      <>
        <Seo
          title={data.markdownRemark.frontmatter.title}
          description={data.markdownRemark.excerpt}
          //   seoImage={data.plant_produttivo.childImageSharp.resize.src}
        />
        <Layout>
          <section className="hero is-gradient is-medium has-text-centered">
            <div className="hero-body">
              <div className="content is-large" style={{ paddingTop: "100px" }}>
                <div className="columns">
                  <div className="column is-6 is-offset-3">
                    <h1 className="title is-white">
                      {data.markdownRemark.frontmatter.title}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section">
            <div className="columns">
              <div className="column is-6 is-offset-3">
                <div
                  className="content is-justified"
                  style={{
                    color: "#111",
                    lineHeight: 1.6,
                    marginLeft: "calc(+1vw)",
                    marginRight: "calc(+1vw)",
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.markdownRemark.html,
                    }}
                  />
                </div>
              </div>
            </div>
          </section>
        </Layout>
      </>
    )
  }


export default withTranslation()(Articolo)

export const articleQuery = graphql`
  query article($localizedPath: String!) {
    markdownRemark(fields: { slug: { eq: $localizedPath } }) {
      excerpt
      html
      frontmatter {
        lang
        path
        title
      }
    }
  }
`
